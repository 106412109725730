header {
  top: 0;
  width: 100%;
  height: 56px;
  z-index: 1000;
  position: fixed;
  background: linear-gradient(90deg, #FFF 8.02%, #00CDCD 64.53%, #6A00A7 90.63%);
  box-shadow: 0 2px 8px 0 var(--color-cool-12);
  img.logo {
    top: 8px;
    left: 32px;
    position: absolute;
    z-index: 9;
  }
  span {
    position: absolute;
    left: 148px;
    color: white;
    bottom: 8px;
    font-size: 12px;
  }
}

.wizard-layout {
  padding-top: 56px;
  
  div {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    img.person {
      position: sticky;
      top: 70px;
      margin: 90px 15px 0 0;
      transform: scale(0.9);
      @media (max-width: 700px) {
        display: none;
      }
    }
  }
}