.flatButton {
    height: 40px;
    margin: auto;
    padding: 8px 24px;
    font-size: 14px;
    box-shadow: none;
    font-weight: bold;
    border-radius: 24px;
    color: #f44248;
}

.flatButton:hover {
    background-color: rgba(76, 144, 245, 0.12);
}

.flatButton:active {
    box-shadow: none;
    background-color: rgba(76, 144, 245, 0.24);
}

.MuiButton-containedPrimary {
    background-color: #4c90f5 !important;
    color: #fff;
}

.MuiButton-root {
    font-size: 0.765625rem !important;
    font-family: 'Ubuntu', sans-serif !important;
    letter-spacing: 0 !important;
}

.MuiInputBase-input {
    /* font: inherit !important; */
    font-size: 0.875rem !important;
    font-family: 'Ubuntu', sans-serif !important;

}

.MuiFormLabel-root {
    font-size: 0.875rem !important;
    font-family: 'Ubuntu', sans-serif !important;
}

.MuiSvgIcon-root {
    font-size: 1.3125rem !important;
}

.seachInput {
    width: 100%;
    height: 40px;
    margin-bottom: 20px !important;

}

.modal-buttons {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.modal-buttons>button:first-child {
    margin-right: 20px;
}

.modal-buttons>button:last-child {
    margin-left: 20px;
}

.LabelReportFilter {
    margin: 10px 0;
    display: flex;
    gap: 5px 7px;
    flex-wrap: wrap;
    overflow: scroll;
    overflow-y: visible;
    overflow-x: hidden;
    min-height: 42px;
    max-height: 50px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    margin: 15p x 0px 20px;
}

.btn-exit {
    background: none;
    border: none;
    cursor: pointer;
}

.modal-title {
    display: flex;
}