html {
  --color-white: #ffffff;
  --color-white-12: rgba(255, 255, 255, 0.12);
  --color-white-24: rgba(255, 255, 255, 0.24);
  --color-cool: #263238;
  --color-cool-03: rgba(38, 50, 56, 0.03);
  --color-cool-06: rgba(38, 50, 56, 0.06);
  --color-cool-12: rgba(38, 50, 56, 0.12);
  --color-cool-30: rgba(38, 50, 56, 0.3);
  --color-cool-50: rgba(38, 50, 56, 0.5);
  --color-cool-70: rgba(38, 50, 56, 0.7);
  --color-error: #f44336;

  -webkit-user-drag: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}