html {
  --color-white: #ffffff;
  --color-white-12: rgba(255, 255, 255, 0.12);
  --color-white-24: rgba(255, 255, 255, 0.24);
  --color-cool: #263238;
  --color-cool-03: rgba(38, 50, 56, 0.03);
  --color-cool-06: rgba(38, 50, 56, 0.06);
  --color-cool-12: rgba(38, 50, 56, 0.12);
  --color-cool-30: rgba(38, 50, 56, 0.3);
  --color-cool-50: rgba(38, 50, 56, 0.5);
  --color-cool-70: rgba(38, 50, 56, 0.7);
  --color-error: #f44336;
  -webkit-user-drag: none; }

body {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

* {
  box-sizing: border-box; }

header {
  top: 0;
  width: 100%;
  height: 56px;
  z-index: 1000;
  position: fixed;
  background: linear-gradient(90deg, #FFF 8.02%, #00CDCD 64.53%, #6A00A7 90.63%);
  box-shadow: 0 2px 8px 0 var(--color-cool-12); }
  header img.logo {
    top: 8px;
    left: 32px;
    position: absolute;
    z-index: 9; }
  header span {
    position: absolute;
    left: 148px;
    color: white;
    bottom: 8px;
    font-size: 12px; }

.wizard-layout {
  padding-top: 56px; }
  .wizard-layout div {
    display: flex;
    justify-content: center;
    align-items: flex-start; }
    .wizard-layout div img.person {
      position: sticky;
      top: 70px;
      margin: 90px 15px 0 0;
      transform: scale(0.9); }
      @media (max-width: 700px) {
        .wizard-layout div img.person {
          display: none; } }

form.wizard-form {
  width: 363px;
  display: block;
  padding-bottom: 32px; }
  @media (max-width: 700px) {
    form.wizard-form {
      width: 90%; } }
  form.wizard-form > .form-field {
    display: block;
    margin-top: 16px; }
    form.wizard-form > .form-field label[for="identification"] + .MuiInputBase-formControl {
      display: block; }
    form.wizard-form > .form-field .MuiIconButton-label {
      color: #00CDCD; }

.WizardForm-root-1 {
  height: auto !important; }

.warning {
  width: 100%;
  height: 100%;
  max-width: 368px;
  max-height: 287px; }

.processing {
  padding: 24px 0;
  text-align: center; }

.text-center {
  text-align: center; }

.main-title {
  margin-top: 0;
  padding: 24px 30px;
  color: #002955;
  font-size: 22px !important;
  font-weight: 400;
  line-height: normal !important; }
  @media (max-width: 700px) {
    .main-title {
      font-size: 18px !important;
      padding: 24px 0; } }

.footer {
  margin-top: 40px; }
  .footer button {
    position: absolute;
    bottom: 8px;
    right: 8px; }

.dialog-error {
  white-space: pre;
  text-align: left; }
  .dialog-error h3 {
    line-height: 1.5; }
  .dialog-error h6 {
    margin-bottom: 12px; }

h5 {
  margin-top: 5px !important;
  font-size: 14px !important; }

.list-error {
  color: #f44336 !important; }

.list-ok {
  color: #3fd82b !important; }

.label-error {
  color: #f44336;
  margin-top: 8px;
  font-size: 0.65625rem;
  font-family: "Ubuntu", sans-serif; }

.captcha {
  margin: 24px auto;
  padding-left: 12px;
  min-height: 78px; }
  .captcha > div > div {
    margin: auto; }

.inline {
  width: 100%;
  display: flex;
  position: relative; }
  .inline > .inline-combo {
    width: 95%;
    margin-top: 16px;
    margin-right: 16px; }
    .inline > .inline-combo .MuiIconButton-label {
      color: #00CDCD; }
  .inline > .MuiFormControl-root.inline-textfield {
    margin-top: 16px; }
  .inline > .button-container {
    top: 20px;
    right: -110px;
    width: 100px;
    position: absolute; }
    .inline > .button-container > button + button {
      margin-left: 8px; }
      .inline > .button-container > button + button[hidden] {
        display: none; }

.extraLargeButton {
  padding: 12px 94px !important;
  white-space: nowrap !important; }

.modal {
  display: flex;
  align-items: center;
  justify-content: center; }

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 920px; }

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px; }

.table {
  min-width: 650; }

::-webkit-scrollbar {
  width: 5px;
  -webkit-appearance: none;
  /* width of the entire scrollbar */ }

::-webkit-scrollbar-track {
  background: #fff;
  /* border-radius: 10px;  */
  /* color of the tracking area */ }

::-webkit-scrollbar-button:increment,
::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-thumb {
  background-color: #7979799e;
  border-radius: 24px;
  border: 0px solid #f1f2f3; }

.flatButton {
    height: 40px;
    margin: auto;
    padding: 8px 24px;
    font-size: 14px;
    box-shadow: none;
    font-weight: bold;
    border-radius: 24px;
    color: #f44248;
}

.flatButton:hover {
    background-color: rgba(76, 144, 245, 0.12);
}

.flatButton:active {
    box-shadow: none;
    background-color: rgba(76, 144, 245, 0.24);
}

.MuiButton-containedPrimary {
    background-color: #4c90f5 !important;
    color: #fff;
}

.MuiButton-root {
    font-size: 0.765625rem !important;
    font-family: 'Ubuntu', sans-serif !important;
    letter-spacing: 0 !important;
}

.MuiInputBase-input {
    /* font: inherit !important; */
    font-size: 0.875rem !important;
    font-family: 'Ubuntu', sans-serif !important;

}

.MuiFormLabel-root {
    font-size: 0.875rem !important;
    font-family: 'Ubuntu', sans-serif !important;
}

.MuiSvgIcon-root {
    font-size: 1.3125rem !important;
}

.seachInput {
    width: 100%;
    height: 40px;
    margin-bottom: 20px !important;

}

.modal-buttons {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.modal-buttons>button:first-child {
    margin-right: 20px;
}

.modal-buttons>button:last-child {
    margin-left: 20px;
}

.LabelReportFilter {
    margin: 10px 0;
    display: flex;
    grid-gap: 5px 7px;
    gap: 5px 7px;
    flex-wrap: wrap;
    overflow: scroll;
    overflow-y: visible;
    overflow-x: hidden;
    min-height: 42px;
    max-height: 50px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    margin: 15p x 0px 20px;
}

.btn-exit {
    background: none;
    border: none;
    cursor: pointer;
}

.modal-title {
    display: flex;
}
