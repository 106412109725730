form.wizard-form {
  width: 363px;
  display: block;
  padding-bottom: 32px;
  @media (max-width: 700px) {
    width: 90%;
  }

  &>.form-field {
    display: block;
    margin-top: 16px;

    label[for="identification"]+.MuiInputBase-formControl {
      display: block;
    }

    .MuiIconButton-label {
      color: #00CDCD;
    }
  }
}

.WizardForm-root-1 {
  height: auto !important;
}

.warning {
  width: 100%;
  height: 100%;
  max-width: 368px;
  max-height: 287px;
}

.processing {
  padding: 24px 0;
  text-align: center;
}

.text-center {
  text-align: center;
}

.main-title {
  margin-top: 0;
  padding: 24px 30px;
  color: #002955;
  font-size: 22px !important;
  font-weight: 400;
  line-height: normal !important;
  @media (max-width: 700px) {
    font-size: 18px !important;
    padding: 24px 0;
  }
}

.footer {
  margin-top: 40px;

  button {
    position: absolute;
    bottom: 8px;
    right: 8px;
  }
}

.dialog-error {
  white-space: pre;
  text-align: left;

  h3 {
    line-height: 1.5;
  }

  h6 {
    margin-bottom: 12px;
  }
}

h5 {
  margin-top: 5px !important;
  font-size: 14px !important;
}

.list-error {
  color: #f44336 !important;
}

.list-ok {
  color: #3fd82b !important;
}


.label-error {
  color: #f44336;
  margin-top: 8px;
  font-size: 0.65625rem;
  font-family: "Ubuntu", sans-serif;
}

.captcha {
  margin: 24px auto;
  padding-left: 12px;
  min-height: 78px;

  &>div>div {
    margin: auto;
  }
}

.inline {
  width: 100%;
  display: flex;
  position: relative;

  >.inline-combo {
    width: 95%;
    margin-top: 16px;
    margin-right: 16px;

    .MuiIconButton-label {
      color: #00CDCD;
    }
  }

  >.MuiFormControl-root.inline-textfield {
    margin-top: 16px;
  }

  >.button-container {
    top: 20px;
    right: -110px;
    width: 100px;
    position: absolute;

    >button+button {
      margin-left: 8px;

      &[hidden] {
        display: none;
      }
    }
  }
}

.extraLargeButton {
  padding: 12px 94px !important;
  white-space: nowrap !important;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 920px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.table {
  min-width: 650;
}

::-webkit-scrollbar {
  width: 5px;
  -webkit-appearance: none;
  /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #fff;
  /* border-radius: 10px;  */
  /* color of the tracking area */
}

::-webkit-scrollbar-button:increment,
::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #7979799e;
  border-radius: 24px;
  border: 0px solid #f1f2f3;
}